// Use this to write your custom SCSS

// cookie consent popup
.termsfeed-com---palette-light.termsfeed-com---nb {
  font-family: THICCCBOI,sans-serif !important;
  background-color: white !important;
  right: 2rem !important;
  bottom: 2rem !important;
  box-shadow: 0 .25rem 1.75rem rgba(30,34,40,.07)!important;

  .cc-nb-okagree {
    color: #fff!important;
    background-color: #800020!important;
    border-color: #800020!important;
    border-radius: 50rem!important;
  }

  .cc-nb-changep {
    border-radius: 50rem!important;
  }
}

// contact form
.email-repeat {
  display: none;
}