/*!
Theme Name: Sandbox - Modern & Multipurpose Bootstrap 5 Template
Theme URI:	http://themes.iki-bir.com/sandbox/start.html
Version:	1.1.0
Author:		elemis
*/

// User colors
@import 'user-colors';

// Variables
@import "variables";

// User variables
@import 'user-variables';

// Utilities
@import "utilities";

// Theme Root
@import "theme/root";

// Bootstrap
@import "bootstrap";

// Bootstrap Utilities API
@import "../../../node_modules/bootstrap/scss/utilities/api";

// Theme
@import "theme/theme";

// Bootstrap Helpers
@import "helpers";

// User
@import 'user';

@import "custom";


